/* Applications Container */
.Applications-container {
  padding: 20px;
}

.Applications-header {
  font-size: 24px;
  margin-bottom: 20px;
}

.Applications-table {
  margin-top: 20px;
}

.Applications-link {
  color: #3498db;
  cursor: pointer;
}

/* File Card Styles */
.file-card {
  overflow: visible; /* Allows the dropdown to extend outside the card */
  position: relative; /* Ensures the dropdown positions itself correctly */
}

.file-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px; /* Rounded corners */
  padding: 20px; /* Internal padding for card */
  transition: box-shadow 0.3s ease;
  min-height: 225px; /* Minimum height for uniformity */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.files-container {
  margin-bottom: 5rem !important;
}






.folder-ellipse-icon {
  font-size: 18px;
}

.file-icon {
  color: #262626 ; /* Icon color */
  margin-bottom: 10px;
}

.file-title {
  font-size: 1.1rem; /* Reverting to larger size for file title */
  color: #000000; /* Darker color for the title */
  font-weight: 500;
  letter-spacing: .5px; /* Keep bold for file title */
  margin-top: .5rem;
  font-family:Arial, Helvetica, sans-serif;
}

.file-divider {
  margin: 10px 0;
  border: none;
  border-top: 1px solid #ddd;
}

.same-input-style {
  border-radius: 4px !important; /* Set a consistent border-radius */
  
}

.main-breadcrumb-item {
  color: #000000 !important; /* Remove link styles */
}


.breadcrumb-title {
  font-size: 1.8rem; /* Matches breadcrumb-title styling */
  font-weight: bold;
  letter-spacing: 1px;
  color: #3a3a3a !important; /* Ensure the text is black */
  text-decoration: none !important; /* Ensure no underline */
}

.breadcrumb-main {
  font-size: 1.8rem; /* Matches breadcrumb-title styling */
  font-weight: bold;
  letter-spacing: 1px;
  color: #3a3a3a !important;
  text-decoration: none !important; /* Ensures no underline */
  pointer-events: auto; /* Makes it clickable */
}

.breadcrumb-main:hover {
  color: #000000 !important;

}

.applications-title {
  font-size: large;
  margin-bottom: 20px;
}

.dropdown-space {
  margin-bottom: 2.2rem !important;
}


.active-breadcrumb {
  color: #343434 !important;
  text-decoration: none !important;
}


.employee-log-modal-title {
  font-weight: 550 !important;
  letter-spacing: 1px !important;
  font-family:Arial, Helvetica, sans-serif !important;
  color: #333 !important;
}



.view-file-btn {
  color: #3498db; /* Button color */
  margin-top: 5%;
}

/* Folder Card Styles */
.folder-card {
  border: 1px solid #ddd;
  padding: 15px;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  background-color: #f9f9f9;
  display: flex;
  justify-content: flex-start;
}

/* Hide caret only for dropdowns with class 'no-caret' */


.dropdown-toggle::after {
  display: none !important;
}

/* View button base styles */
.view-icon-button {
  border: 1px solid #ddd;
  color: #333;
  outline: none;
  box-shadow: none;
}




.view-icon-button:focus,
.view-icon-button:active {
  box-shadow: none;
  outline: none;
}




.folder-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.folder-icon {
  margin-right: 10px; /* Space between icon and text */
}

.folder-title {
  font-size: 1rem; /* Smaller size only for folder titles */
  font-weight: 500;
  color: #333;
  margin: 0; /* Remove any margin */
  text-align: left;
  font-family:Arial, Helvetica, sans-serif;
}

.folder-title {
  white-space: nowrap;      /* Prevents text from wrapping to the next line */
  overflow: hidden;         /* Hides the overflow text */
  text-overflow: ellipsis;  /* Adds the "..." at the end */
  max-width: 20ch;          /* Limits the length to 24 characters */
}

.file-title {
  white-space: nowrap;      /* Prevents text from wrapping to the next line */
  overflow: hidden;         /* Hides the overflow text */
  text-overflow: ellipsis;  /* Adds the "..." at the end */
  max-width: 30ch; 
}


.folder-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

/* Move to Folder Modal */
.loading-spinner {
  text-align: center;
  font-size: 1.5rem;
  color: #3498db;
}

@media (max-width: 768px) {
  .file-title {
    font-size: 0.95rem; /* Slightly smaller on mobile if you want */
  }

  /* If any spacing is too large on mobile, reduce it here */
  .mb-4 {
    margin-bottom: 1.5rem !important;
  }
}
