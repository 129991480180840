.contact-form-container {
  padding: 50px; /* Chose 50px from the remote branch */
  background: linear-gradient(to right, #D77124, #ED9A2B); /* Orange gradient */
  color: white;
  font-family: 'Inter', sans-serif;
}

.form-content {
  padding: 20px; /* Adjust padding as needed */
}

.form-content h2 {
  font-size: 2em; /* Merged value */
  font-weight: bold; /* Merged value */
  color: black; /* Adjust as needed */
  font-family: 'Julius Sans One', sans-serif; /* Chose 'Julius Sans One' from the remote branch */
}

.form-content p {
  font-size: 1.2em; /* Chose 1.2em from your branch */
  color: white; /* Adjust as needed */
}

.contact-form .form-group {
  margin-bottom: 15px;
}

.contact-form .form-group label {
  margin-bottom: 5px;
  color: black; /* Adjust as needed */
}

.contact-form .form-control {
  padding: 10px;
  border-radius: 5px;
  border: none;
  font-size: 1em;
  color: #333;
}

.contact-form .form-control::placeholder {
  color: #999;
}

.contact-form .btn-submit {
  padding: 10px 25px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold; /* Merged value */
  transition: background-color 0.3s ease;
}

.contact-form .btn-submit:hover {
  background-color: #333;
}

/* Custom CSS to ensure the form row styles correctly */
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-group {
  padding-right: 5px;
  padding-left: 5px;
}

.success-message {
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  
}

.success-message h2 {
  color: #000000; /* Green color for success */
  font-size: 1.8rem;
  margin-bottom: 10px;
  font-weight: bold;
  font-family: 'Julius Sans One', sans-serif; /* Chose 'Julius Sans One' from the remote branch */

}

.success-message p {
  color: #ffffff;
  font-size: 1.2rem;
  font-family: 'K2D', sans-serif; /* Chose 'Julius Sans One' from the remote branch */

}
