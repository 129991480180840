.rate-sheet-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .rate-sheet-title {
    text-align: center;
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .rate-sheet-form {
    display: flex;
    flex-direction: column;
  }
  
  .rate-sheet-form-group {
    margin-bottom: 15px;
  }
  
  .rate-sheet-label {
    font-size: 16px;
    color: #333;
    display: flex;
    justify-content: space-between;
  }
  
  .rate-sheet-input {
    width: 100px;
    padding: 5px;
    margin-left: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .rate-sheet-button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #3498db; /* Blue color similar to the sidebar */
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .rate-sheet-button:hover {
    background-color: #226896; /* Slightly darker blue for hover */
  }
  
  .rate-sheet-container {
    max-width: 100%;
    padding: 20px;
  }
  
  .rate-sheet-title {
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .download-button {
    width: 100%;
    max-width: 250px;
    padding: 10px;
    font-size: 1rem;
  }
  
  .table-responsive {
    overflow-x: auto;
  }
  
  .rate-sheet-table {
    width: 100%;
    font-size: 1rem;
  }
  
  .rate-sheet-input {
    width: 100%;
    max-width: 120px;
    text-align: center;
  }
  
  .rate-sheet-hours {
    min-width: 40px;
    max-width: 60px;
    text-align: center;
  }

  @media (max-width: 768px) {
    .rate-sheet-title {
      font-size: 1.75rem;
    }
  
    .download-button {
      font-size: 0.9rem;
      padding: 8px;
    }
  
    .rate-sheet-table {
      font-size: 0.9rem;
    }
  
    .rate-sheet-input {
      max-width: 100px;
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 576px) {
    /* reduce padding for the input fields on very small screens */
    .rate-sheet-input {
      padding: 0.3rem 0.5rem;
      font-size: 0.9rem;
    }
  }
  