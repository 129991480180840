.contact-page-container {
  width: 100%;
  padding: 20px;
  background: none; /* Remove background */
  color: black;
  font-family: 'Inter', sans-serif;
}

.contact-formspree {
  color: black !important;
}

.contact-page {
  width: 100%;
  padding: 20px;
}

.contact-page .form-group {
  margin-bottom: 15px;
}

.contact-page label {
  margin-bottom: 5px;
  color: black;
}

/* Custom styles for the first name, last name, subject, and email inputs */
.contact-page-first-name,
.contact-page-last-name,
.contact-page-subject,
.contact-page-email {
  width: 100%;
  padding: 8px; /* Same padding */
  border-radius: 4px !important; /* Same border radius */
  border: 1px solid #666 !important; /* Same lighter border color */
  font-size: 0.9em; /* Same font size */
  color: #333;
  background-color: #f9f9f9; /* Same lighter background */
}

.contact-page-first-name::placeholder,
.contact-page-last-name::placeholder,
.contact-page-subject::placeholder,
.contact-page-email::placeholder {
  color: #999;
}

/* Custom styles for the message input */
.contact-page-message {
  width: 100%;
  padding: 12px;
  border-radius: 6px;
  border-radius: 4px !important; /* Same border radius */
  border: 1px solid #666 !important; /* Same lighter border color */
  font-size: 0.9em; /* Same font size */
  color: #333;
  background-color: #f9f9f9; /* Same lighter background */
}

.contact-page-message::placeholder {
  color: #888;
}

/* Custom styles for the submit button */
.contact-page-submit {
  padding: 10px 25px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.contact-page-submit:hover {
  background-color: #333;
}
