.background-image-container {
    background-size: cover;
    background-image: url('../images/logo.webp');
    background-position: center;
    background-repeat: no-repeat;
    height: 80vh;
    background-size: 50%; /* Adjust this value to zoom out the image */

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .leave-head {
    font-weight: 800 !important;
    font-family: 'Julius Sans One', sans-serif !important;

  }
  
  /* Add the rest of your existing CSS here */
  
  
  /* Feedback Card Styles */
  .feedback-card {
    background: linear-gradient(to right, #d77124fa, #ed992bfa);
    padding: 80px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    width: 100%;
    max-width: 800px !important;
  }
  
  /* Loading Spinner Container */
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  }
  
  /* Thank You Message after Form Submission */
  .thank-you-message {
    text-align: center;
  }
  
  /* Rating Stars Styling */
  .stars {
    display: flex;
    justify-content: center;
    gap: 5px;
  }
  
  .star {
    cursor: pointer;
    color: #d9d9d9;
    font-size: 80px;
    margin-left: .5rem;
    margin-right: .5rem;
    transition: color 0.2s;
  }
  
  .star.filled {
    color: #f0c14b;
  }
  
  /* Feedback Form Styling */
  .feedback-container {
    padding: 20px;
  }
  
  .feedback-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .feedback-form input,
  .feedback-form textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .feedback-form button {
    background-color: #000000;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .form-control {
    font-family: 'K2D', sans-serif;

  }
  
  .feedback-form button:hover {
    background-color: #333;
  }
  
  /* Modal Styles */
  .feedback-modal {
    padding: 20px;
    background-color: #ffffff;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .feedback-card {
      margin: 10px;
    }
  }
  
  /* Smaller devices (phones, less than 768px) */
  @media (max-width: 480px) {
    .feedback-form button {
      width: 100%;
    }
  }
  