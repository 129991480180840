@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@700&display=swap');

.footer-container {
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: #f3f6fd;
}

.footer-style {
    font-size: 1rem !important;
    font-family: 'K2D', sans-serif;
}

.footer-logo {
    width: 150px; /* Adjust this value to make the logo larger */
    height: auto; /* Maintain aspect ratio */
}

.contact-us-column {
    margin-left: auto; /* Pushes the column to the right */
    padding-right: 60px; /* Increase padding to move it further right */
    text-align: left; /* Align content to the left */
}

.footer-card {
    width: 100%;
    padding: 3%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: linear-gradient(to right, #D77124, #ED9A2B); /* Orange gradient */
    color: white;
    font-family: 'K2D', sans-serif;

}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

ul > li {
    padding: 4px;
    color: #C5CAE9;
}

ul > li:hover {
    color: #ED9A2B; /* Match your footer color scheme */
    cursor: pointer;
}

hr {
    border-width: 3px;
}

.social > a {
    padding: 1%;
    font-size: 24px;
    color: #ffffff;
    margin-right: 10px;
    text-decoration: none;
}

.social > a:hover {
    color: #ED9A2B; /* Match your footer color scheme */
    cursor: pointer;
}

.heading {
    font-family: 'K2D', sans-serif;
    color: white;
}

.divider {
    border-top: 2px solid white;
}
