@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

.info-row1 {
  margin-top: 3rem;
}

.info-row {
  margin-top: 0rem;
}

@media (max-width: 991.98px) {
  .img-card {
    margin-top: 2rem; /* Add margin above each image card on screens smaller than 992px */
  }
}

.card {
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  overflow: hidden;
}

.about-head {
  font-size: 2.5rem;
}

.card-text {
  font-family: 'K2D', sans-serif;
  font-size: 1.2rem;
  color: #333;
  text-align: center;
  padding: 20px;
}

.card-img-top {
  border-radius: 8px 8px 0 0;
  object-fit: cover;
  height: 100%;
}
