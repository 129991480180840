@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');



.info-row1 {
  margin-top: 3rem;

}

.info-row {
  margin-top: 1rem;
}

.card {
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  overflow: hidden;
}

.card-title {
  font-family: 'Julius Sans One', sans-serif;
  font-size: 2.3rem;
  margin-bottom: -5px;
  margin-top: 2rem;
  text-align: center;
  color: #D77124;
  font-weight: 900;
}

.card-text {
  font-family: 'K2D', sans-serif;
  font-size: 1.2rem;
  color: #333;
  text-align: center;
  padding: 20px;

}

.card-img-top {
  border-radius: 8px 8px 0 0;
  object-fit: cover;
  height: 100%;
}


@media (max-width: 992px) {
  .info-img {
    margin-top: 2rem;
  }
  .info-img1 {
    margin-top: 4rem;
  }

  .info-1 {
    margin-top: 2rem !important;
  }

  


}