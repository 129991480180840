@import url('https://fonts.googleapis.com/css2?family=Julius+Sans+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Julius+Sans+One&family=K2D:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');
.home .hero {
  background-position: center; /* Centers the background image */
  background-repeat: no-repeat;
  background-size: contain; /* Adjust this value as needed */
  display: flex;
  background-size: 50%; /* Adjust this value to zoom out the image */
  justify-content: center;
  margin-bottom: 5rem;
  align-items: center;
  background-position: center; /* Aligns the background image center horizontally and towards the top */
}



.home .hero-content-home {
  text-align: center;
  color: white; /* Assuming you want white text */
}



.home {
  text-align: left;
}

.hero {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 90vh; /* Full viewport height */
  display: flex;
  align-items: center;
  justify-content: left;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}



.homepage-hero {
  margin-left: 10rem !important;
}

.hero-content-home {
  text-align: left; /* Set to left align */
  padding: 20px;
  z-index: 2;
}

.hero h1 {
  color: white;
  font-size: 4rem;
  margin: 0;
  font-weight: 800;
  font-family: 'Julius Sans One', sans-serif;
}

.hero p {
  font-size: x-large;
  margin: 10px 0;
  font-family: 'K2D', sans-serif;
  color: #ffffff;
}

.hero-buttons {
  margin-top: 37rem;
  font-family: 'K2D', sans-serif;
}

/* Override Bootstrap button styles */
.btn.hero-button {
  background-color: #D77124 !important; /* Ensure the color is applied */
  border: none;
  color: white;
}

.btn.hero-button:hover {
  background-color: #ED9A2B !important; /* Adjust hover color if desired */
}

.hero-button {
  padding: 15px 30px;
  font-size: large;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

@media (min-width: 3100px) {

  .hero-buttons {
display: none !important;  }
}


@media (min-width: 2100px) {

  .hero-buttons {
    margin-top: 75rem !important;
    font-family: 'K2D', sans-serif;
  }
}

@media (min-width: 1450px) {

  .hero-buttons {
    margin-top: 50rem;
    font-family: 'K2D', sans-serif;
  }
}

@media (max-width: 1400px) {
  .hero-content-home {
margin-left: 2rem !important;

  }

  
}

@media (max-width: 992px) {
  .hero-content-home {
margin-left: 0rem !important;
  }
  .home .hero {
  
    background-size: 80% !important; /* Adjust this value to zoom out the image */

  }

  .hero-buttons {
    margin-top: 40rem;
    font-family: 'K2D', sans-serif;
  }
}

@media (max-width: 768px) {
  .hero-content-home {
margin-left: 0rem !important;
  }
  .home .hero {
  
    background-size: 80% !important; /* Adjust this value to zoom out the image */

  }

  .hero-buttons {
    margin-top: 35rem;
    font-family: 'K2D', sans-serif;
  }

  .hero h1 {
    font-size: 3rem !important;
  }

}

@media (max-width: 480px) {

  .hero h1 {
    font-size: 2.5rem !important;
  }

  .hero p {
    font-size: larger !important; 
  }
  .home .hero {
  
    background-size: 100%; /* Adjust this value to zoom out the image */

  }

  .hero-buttons {
    margin-top: 25rem;
    font-family: 'K2D', sans-serif;
  }
}