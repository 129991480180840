/* Leads.css - Matches Invoices.css */

/* Container */
.leads-container {
  padding: 20px;
}

/* Title Styling (Matches Invoices Title) */
.leads-title {
  font-size: 30px;  /* Matches invoice title size */
  font-weight: bold;
  color: #343a40;
  margin-bottom: 10px;
}

/* Add Lead Button (Same as Create Invoice Button) */
.add-lead-btn {
  background-color: #3498db;  /* Same as Invoices */
  color: white;
  font-weight: bold;
  padding: 10px 15px;  /* Same padding */
  border-radius: 20px;  /* Matches rounded design */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 16px;  /* Matches the button text size */
}

.add-lead-btn:hover {
  background-color: #2674a8;
}

/* Divider to Match Invoices */
.divider {
  border-top: 2px solid #dee2e6;
  margin-bottom: 15px;
}

/* Table Styling */
.lead-table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.lead-table th {
  background-color: #007bff;
  color: white;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  padding: 12px;
}

.lead-table td {
  text-align: center;
  font-size: 14px;
  padding: 10px;
  vertical-align: middle;
}

/* Actions Column Buttons */
.internal-buttons {
  font-size: 14px;
  font-weight: 500;
  background-color: #3498db;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}

.internal-buttons:hover {
  background-color: #2674a8;
}

/* Delete Button */
.delete-button {
  background-color: #dc3545;
  border: none;
  color: white;
  padding: 5px 10px;
  font-weight: 500;
  border-radius: 5px;
}

.delete-button:hover {
  background-color: #c82333;
}
