/* Header-specific navbar styling */
header.header-navbar {
  background: linear-gradient(to right, #D77124, #ED9A2B);
  padding: 10px 20px;
  font-family: 'K2D', sans-serif;
}

.header-navbar .navbar-brand img {
  height: 65px;
}

.header-navbar .navbar-nav {
  margin-left: auto;
}

.header-navbar .navbar-nav .nav-link {
  color: white;
  margin: 10px 0; /* Adds margin above and below each nav link */
  font-size: large;
  text-decoration: none;
  font-weight: bold;
  position: relative;
  display: inline-block;
  transition: color 0.3s ease-in-out;
}

/* Apply underline only for header nav links */
.header-navbar .navbar-nav .nav-link:not(.btn-login)::before {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: white;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.header-navbar .navbar-nav .nav-link:hover::before,
.header-navbar .navbar-nav .nav-link.active::before {
  visibility: visible;
  width: 100%;
  background-color: white;
}

.header-navbar .navbar-nav .nav-link.active {
  color: white;
}

/* Button-specific styles */
.header-navbar .navbar-nav .nav-link.btn-login {
  background-color: black;
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
}

.header-navbar .navbar-nav .nav-link.btn-login:hover {
  background-color: #333;
}

/* Hide the default navbar-toggler-icon */
.header-navbar .navbar-toggler-icon {
  display: none;
}

/* Remove background, border, and outline from the burger menu */
.header-navbar .navbar-toggler {
  border: none;
  background: none;
  outline: none;
  box-shadow: none;
  padding: 0;
  position: relative;
  width: 30px; /* Adjust the width as needed */
  height: 24px; /* Adjust the height to match the total height of the three lines */
  cursor: pointer;
}

/* Ensure no background or outline appears when focused or active */
.header-navbar .navbar-toggler:focus,
.header-navbar .navbar-toggler:active {
  outline: none;
  box-shadow: none;
  background: none;
}

/* Custom burger menu icon */
.header-navbar .navbar-toggler .line {
  display: block;
  width: 100%;
  height: 2px;
  background-color: white;
  position: absolute;
  transition: all 0.3s ease-in-out;
}

/* Position the lines */
.header-navbar .navbar-toggler .line:nth-child(1) {
  top: 0;
}

.header-navbar .navbar-toggler .line:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}

.header-navbar .navbar-toggler .line:nth-child(3) {
  bottom: 0;
}

/* Transform to X icon */
.header-navbar .navbar-toggler.open .line:nth-child(1) {
  transform: rotate(45deg);
  top: 50%;
}

.header-navbar .navbar-toggler.open .line:nth-child(2) {
  opacity: 0; /* Hide the middle line */
}

.header-navbar .navbar-toggler.open .line:nth-child(3) {
  transform: rotate(-45deg);
  top: 50%;
}
