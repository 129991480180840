.filters {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-bottom: 20px;
}

.filter-group {
  display: flex;
  align-items: center;
  gap: 5px;
}

.filter-icon {
  color: #6c757d;
}

.filter-select,
.filter-datepicker {
  min-width: 200px;
}

.clear-filters-btn {
  margin-left: auto;
  background-color: #f8d7da;
  border: 2px solid #f5c6cb;
  color: #721c24;
  font-weight: 500;
}

.clear-filters-btn:hover {
  background-color: #721c24;
  color: white;
}

.download-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.download-btn {
  background-color: #007bff;
  color: white;
  border: none;
  font-weight: 500;
  padding: 10px 15px;
  /* for small screens, 100% width */
  @media (max-width: 576px) {
    width: 100%;
  }
}

.input-group .form-control {
  border-radius: 0 !important; /* Reset border radius for seamless connection */
}

.input-group .rounded-start {
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.input-group .rounded-end {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

@media (max-width: 768px) {
  .filters {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .filter-group {
    width: 100%;
    margin-bottom: 10px;
  }

  .download-buttons {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}