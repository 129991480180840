.contact-container {
  padding: 0;
  position: relative;
}

.map-container iframe {
  transition: opacity 0.3s ease-in-out; /* Adjust the lead-in speed */
  opacity: 0; /* Initial state */
}

.map-container iframe[data-loaded="true"] {
  opacity: 1; /* Final state after loading */
}

.contact-page1 {
  margin-top: 3rem !important;
  margin-bottom: 5rem !important;
}

.contact-header {
  background: linear-gradient(to right, #D77124, #ED9A2B); /* Keep the orange gradient */
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  padding-bottom: 5% !important; /* Push the content 10% above the bottom */
}

.contact-header h1, 
.contact-header p {
  margin: 0;
}

.contact-jumbo {
  font-size: x-large;
  margin: 10px 0;
  font-family: 'K2D', sans-serif;
  color: #ffffff;
}

.form-title {
  font-size: 2.5rem !important;
  color: rgb(0, 0, 0);
  font-family: 'Julius Sans One', sans-serif;
  font-weight: 800;
}

.contact-header .display-4 {
  font-size: 3.5rem;
  color: white;
  font-family: 'Julius Sans One', sans-serif;
  font-weight: 800;
}

.contact-header .lead {
  font-size: 1.25rem;
  color: whitesmoke;
}

.contact-form-wrapper {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-title {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
  font-weight: 600;
  color: #d97706;
}

.contact-map {
  position: relative;
}

.map-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.contact-map iframe {
  width: 100%;
  height: 100%;
  min-height: 400px;
  border: none;
  position: relative;
  z-index: 1;
}

.map-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(215, 113, 36, 0.1), rgba(237, 154, 43, 0.1)); /* Light orange gradient with 10% opacity */
  z-index: 2;
  pointer-events: none;
}





@media  (max-width: 992px) {
  .contact-header {
    height: 20vh;

  }

  
}

@media  (max-width: 768px) {


  .contact-header .display-4 {
    font-size: 3rem;
    
  }
}

@media  (max-width: 550px) {


  .contact-header .display-4 {
    font-size: 2.5rem;
    
  }
  .contact-header {
    padding-bottom: 8% !important; /* Push the content 10% above the bottom */

  }
}

@media (max-width: 992px) {
  .contact-map {
    margin-top: 20px; /* Adds margin above the map */
  }

  /* Additional adjustments if needed */
}