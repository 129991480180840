/* Base Styles */
.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}

.login-hero {
  margin-bottom: 2rem;
}

.login-logo {
  max-width: 200px;
  width: 100%;
  height: auto;
}

.login-container {
  width: 100%;
  max-width: 1000px;
  padding: 0 1rem;
}

.login-card {
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.login-tabs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.login-tab {
  flex: 1;
  text-align: center;
  padding: 1rem;
  cursor: pointer;
  background-color: #f1f1f1;
  border-radius: 5px;
  margin: 0 5px;
  font-size: 1.15rem;
  font-weight: 550;
  color: #333;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.login-tab:hover {
  background-color: #ED9A2B;
  color: white;
}

.login-active-tab {
  background-color: #ED9A2B;
  color: white;
}

.login-form-data {
  margin-bottom: 1rem;
}

.login-forms-inputs {
  display: flex;
  flex-direction: column;
}

.login-label {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.login-input-container {
  position: relative;
}

.login-form-control {
  width: 100% !important ;
  padding: 0.75rem !important;
  padding-right: 2.5rem !important; /* Space for the eye icon */
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
  box-sizing: border-box ! ;
  font-size: 1rem !important;
}

.login-form-control:focus {
  border-color: #ED9A2B !important;
  outline: none !important;
}

.login-password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 1.2rem;
  color: #666;
}

.login-invalid-feedback {
  color: red;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.login-btn {
  width: 100%;
  background-color: #000000;
  color: white;
  padding: 0.75rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.15rem;
  font-weight: 550;
  margin-top: 2rem;
  transition: background-color 0.3s ease;
}

.login-btn:hover {
  background-color: #d48825;
}

.login-form-check {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.login-form-check-input {
  margin-right: 0.5rem;
  width: 1.2rem;
  height: 1.2rem;
}

.login-form-check-label {
  font-size: 1rem;
}

.login-is-invalid {
  border-color: red;
}

.login-is-invalid:focus {
  border-color: red;
  outline: none;
}

/* Responsive Styles */
@media (max-width: 576px) {
  .login-card {
    padding: 1.5rem;
  }

  .login-tab {
    font-size: 1rem;
  }

  .login-label {
    font-size: 1rem;
  }

  .login-btn {
    font-size: 1rem;
  }
}

.login-forgot-password {
  text-align: right;
  margin-top: 8px;
}

.login-forgot-password-btn {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
}

.login-forgot-password-btn:hover {
  text-decoration: underline;
}
