.internal-buttons {
    font-size: medium;
    font-weight: 500;
    background-color: #3498db;
    border-color: #3498db;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
    color: white;
}

.internal-buttons:hover {
    background-color: #2674a8 !important;
    border-color: #3498db;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3) !important; /* Slightly larger shadow on hover */
    color: white;
  }

.internal-delete-buttons {
    font-size: medium;
    font-weight: 500;
}

.internal-edit-buttons {
    font-size: medium;
    font-weight: 450;
}

.detailed-head {
    font-weight: bold;
    font-size: large;
}

.small-button {
    font-size: 15px; /* Make text smaller */
    padding: 5px 8px; /* Reduce padding */
  }
  
  .delete-button {
    background-color: #dc3545; /* Ensure red color for delete */
    border-color: #dc3545;
  }
  
  .delete-button:hover {
    background-color: #c82333; /* Darker red on hover */
  }
  
  @media (max-width: 576px) {
    .dashboard-add-client-btn {
      font-size: 14px;
      padding: 6px 10px;
    }
  }
  