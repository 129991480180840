/* Dashboard specific container */
.dashboard-container {
  position: relative;
  height: 100vh;
  background-color: #f4f7fc;
  padding: 20px;
}

.dashboard-client-container {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  transition: box-shadow 0.3s ease;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.dashboard-client-container:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.dashboard-client-container h3 {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 1.5rem;
  
  color: #333;
}

.confirm-delete {
  font-size: large;
  font-weight: 550;
}

.confirm-name {
  font-size: large;
  font-weight: bold;
}

.client-info-title {
  font-size: 1rem;
  color: #888; /* Light gray for titles */
  margin-bottom: 5px;
  text-transform: uppercase;
}

.client-info-content {
  font-size: 1rem;
  font-weight: 500;
  color: #000000; /* Darker gray for content */
  margin-bottom: 15px;
}


/* Main content section styling */
.dashboard-main-content {
  padding: 20px;
  background-color: #ecf0f1;
  border-radius: 8px;
}

/* Table view styling */
.table {
  width: 100%;
  margin-bottom: 20px;
  border-collapse: collapse;
}

.table th, .table td {
  padding: 12px 15px;
  text-align: left;
  border: 1px solid #ddd;
}

.table th {
  background-color: #3498db;
  color: white;
  font-weight: bold;
}

.table tr:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

.table td {
  vertical-align: middle;
}

/* Client card view styling */
.dashboard-client-container {
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  background-color: #f9f9f9;
  transition: box-shadow 0.3s ease;
  cursor: pointer;
}

.dashboard-client-container:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dashboard-client-container h3 {
  margin-bottom: 10px;
  font-weight: bold;
}

.dashboard-client-container p {
  margin: 0;
}

.stay-date {
  font-size: large;
  font-weight: bold;
}

.all-modal-form-control {
  width: 100% !important ;
  padding: 0.75rem !important;
  padding-right: 2.5rem !important; /* Space for the eye icon */
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
  box-sizing: border-box ! ;
  font-size: 1rem !important;
}

.all-modal-form-control:focus {
  border-color: #3498db !important;
  outline: none !important;
}


/* Add Client Button styling */
.dashboard-add-client-btn {
  font-weight: 550;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  transition: background-color 0.3s ease;
}


.dashboard-add-client-btn:hover {
  background-color: #2674a8 !important;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3) !important; /* Slightly larger shadow on hover */

}



.invoice-space {
  margin-right: 1rem;
}

/* Modal styles */
.dashboard-modal-header {
  background-color: #3498db;
  color: white;
  border-bottom: none;
}

.dashboard-modal-title {
  margin: 0;
  font-size: 20px;
}

.dashboard-modal-footer {
  border-top: none;
}

.dashboard-button {
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.dashboard-button:hover {
  transform: scale(1.02);
}

/* View Icon and Filter Icon Styling */
.view-icon {
  font-size: 24px;
  margin-right: 15px;
  cursor: pointer;
  color: #3498db;
}

.view-icon:hover {
  color: #226896;
}

.filter-icon {
  font-size: 24px;
  cursor: pointer;
  color: #3498db;
}



.filter-icon:hover {
  color: #226896;
}

/* Search bar styling */
form input[type="text"] {
  border-radius: 30px;
  padding: 10px;
  border: 1px solid #ccc;
  width: 100%;
}

form input[type="text"]:focus {
  border-color: #3498db;
  outline: none;
}

.invoice-type-buttons {
  padding-right: 115px;
  margin-right: 50px;
  margin-top: -50px;
}

.all-modal-form-control-bottom {
  width: 80% !important ;
  padding: 0.75rem !important;
  padding-right: 2.5rem !important; /* Space for the eye icon */
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
  box-sizing: border-box ! ;
  font-size: 1rem !important;
}

/* Make tables and client list scrollable on small screens */
.responsive-container {
  overflow-x: auto;
}

/* Stack buttons & filters on mobile */
@media (max-width: 768px) {
  .custom-filter-width {
    width: 100% !important;
    margin-top: 10px;
  }

  .view-icon-button {
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }
}

/* Make modals more responsive */
.modal-content {
  max-width: 100%;
}

@media (max-width: 576px) {
  .all-modal-form-control {
    font-size: 14px !important;
    padding: 8px !important;
  }
}

.all-modal-form-control {
  margin-top: 5px;
  margin-bottom: 5px;
  /* Adjust padding for better look */
  padding: 10px;
  font-size: 1rem;
}
