.client-filters {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-bottom: 20px;
}

.client-filter-group {
  display: flex;
  align-items: center;
  gap: 5px;
}

.client-filter-icon {
  color: #6c757d;
}

.client-filter-select,
.client-filter-datepicker {
  min-width: 200px;
}

.client-clear-filters-btn {
  margin-left: auto;
  background-color: #d4edda;
  border: 2px solid #c3e6cb;
  color: #155724;
  font-weight: 500;
}

.client-clear-filters-btn:hover {
  background-color: #155724;
  color: white;
}

.client-download-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.client-download-btn {
  background-color: #28a745;
  color: white;
  border: none;
  font-weight: 500;
  padding: 10px 15px;
}

.client-input-group .form-control {
  border-radius: 0 !important; /* Reset border radius for seamless connection */
}

.client-input-group .rounded-start {
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.client-input-group .rounded-end {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

@media (max-width: 768px) {
  .filters-and-download {
    /* force wrapping */
    flex-wrap: wrap;
  }

  .download-buttons {
    width: 100%;
    justify-content: space-between;
  }
}