/* Sidebar.css */

.custom-sidebar {
background-color: #3498db;  
width: 250px;
  transition: all 0.3s ease;
  color: #ffffff; /* White text */
  height: 100vh; /* Full height sidebar */
  position: fixed; /* Fixed sidebar */
}

.sidebar-header {
  font-family: 'K2D', sans-serif;
  color: #ffffff !important;
  margin-left: 1rem;
  margin-top: 1rem;
}

.sidebar-title {
  font-size: 1.5rem; /* Slightly larger text for title */
  margin: 0;
}

.sidebar-divider {
  border-color: rgba(255, 255, 255, 0.1); /* Light white divider */
  width: 100%;
}

.custom-nav {
  width: 100%;
}

.nav-link-custom {
  font-size: 1.15rem; /* Larger text for links */
  color: #ffffff !important; /* White text */
  padding: 15px;
  transition: background-color 0.3s ease, color 0.3s ease;
  position: relative;
}

.nav-link-custom .fa-icon {
  font-size: 1.2rem; /* Slightly larger icon */
}

.nav-link-custom:hover,
.nav-link-custom.active {
  background-color: #226896; /* Darkened blue on hover */
  color: #ecf0f1 !important; /* Light text on hover */
}

.sidebar-profile {
  position: absolute;
  bottom: 20px;
  left: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.2); /* Subtle top border */
}

.profile-image-container {
  border: 2px solid #ffffff; /* White border */
  border-radius: 50%; /* Circular container */
  padding: 2px; /* Space between image and border */
  width: 54px; /* Fixed width */
  height: 54px; /* Fixed height */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff; /* White background for the border */
}

.sidebar-profile-img {
  border-radius: 50%; /* Circular image */
  width: 50px; /* Fixed width */
  height: 50px; /* Fixed height */
  object-fit: cover; /* Ensure image covers container without distortion */
}

.sidebar-profile-name {
  margin-left: 12px; /* Space between image and name */
  font-size: 18px; /* Readable font size */
  color: #fff;
  font-weight: 500; /* Slightly bolder text */
}

/* Borders for top and bottom of each nav link */
.nav-link-custom::before,
.nav-link-custom::after {
  content: '';
  display: block;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2); /* Faint light grey border */
  position: absolute;
  left: 0;
  right: 0;
}

.nav-link-custom::before {
  top: 0;
}

.nav-link-custom::after {
  bottom: 0;
}

/* Highlight borders on hover and keep white on active */
.nav-link-custom:hover::before,
.nav-link-custom:hover::after,
.nav-link-custom.active::before,
.nav-link-custom.active::after {
  background-color: #ffffff; /* White borders on hover and active */
}

/* Optional: Add subtle hover effect to profile section */
.sidebar-profile:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
}


@media (max-width: 1300px) {
  .custom-sidebar {
    width: 140px; /* Shrink sidebar to a proper icon-only size */
    transition: width 0.3s ease;
    overflow: hidden;
  }

  /* Ensure icons are centered */
  .custom-sidebar .nav-link-custom {
    text-align: left;
    padding: 12px 0;
    font-size: 1rem;
    display: flex;
    justify-content: left;
    align-items: left;
  }

  /* Hide text */
  .custom-sidebar .nav-link-custom span {
    display: none;
  }

  /* Show text when hovered */
  .custom-sidebar:hover .nav-link-custom {
    text-align: left;
    padding-left: 15px;
  }

  .custom-sidebar:hover .nav-link-custom span {
    display: inline;
  }

  /* Ensure icons remain consistent */
  .custom-sidebar .nav-link-custom .fa-icon {
    font-size: 1.5rem;
    margin-right: 10px; /* Add spacing between icon and text */
  }
}



/* Responsive adjustments (optional) */
@media (max-width: 999px) {
  .custom-sidebar {
    position: fixed;             /* Ensure it's a separate layer */
    overflow-y: auto;  /* optional, if you have many links */
    left: 0;
    top: 0;
    width: 250px;                /* or however wide it should be */
    height: 100vh;               /* full viewport height */
    background-color: #3498db;
    transform: translateX(-100%);/* hide off-screen by default */
    transition: transform 0.3s ease;
    z-index: 9999;               /* Very high so it's above other elements */
  }
  

  .custom-sidebar.show {
    transform: translateX(0);
  }  

  .sidebar-title {
    font-size: 1.3rem;
  }

  .nav-link-custom {
    font-size: 1rem;
    padding: 12px;
  }

  .nav-link-custom .fa-icon {
    font-size: 1rem;
  }

  .sidebar-profile {
    bottom: 15px;
    left: 8px;
    padding: 8px;
  }

  .sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent dark backdrop */
    z-index: 9998; /* Just behind the sidebar, which is 9999 */
  }

  .profile-image-container {
    width: 48px;
    height: 48px;
  }

  .sidebar-profile-img {
    width: 44px;
    height: 44px;
  }

  .sidebar-profile-name {
    margin-left: 10px;
    font-size: 14px;
  }
}
