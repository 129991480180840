@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

.services-section {
  background-color: rgb(250, 250, 250);
  padding-bottom: 6rem !important;
  padding: 60px 0; /* Ensure enough padding on top and bottom */
}

.modal-title {
  font-family: 'Julius Sans One', sans-serif;
  font-weight: 800;
}

.modal-btn {
  font-family: 'K2D', sans-serif;
  font-weight: bold;
  background-color: #D77124;
  border-color: #ED9A2B;
}

.modal-btn:hover {
  background-color: #b25d1c;
  border-color: #ED9A2B;

}

.modal-labels {
  font-family: 'K2D', sans-serif;
font-weight: bold;
}

.service-icon {
  color: #D77124 !important; 
}

.bsb-service-6 .service-icon {
  font-size: 32px;
}

.bsb-service-6 .card {
  height: 100%; /* Ensure cards take full height */
  background-color: transparent;
  transition: background-color 0.5s ease, box-shadow 0.5s ease, transform 0.5s ease;
  box-shadow: none;
  border: none;
}

.bsb-service-6 .card-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
}

.bsb-service-6 .card-body h3 {
  font-family: 'Julius Sans One', sans-serif;
  font-weight: 700;
  color: #000000;
  margin-bottom: 1rem;
  font-size: 28px !important;
}

.services-header {
  font-family: 'Julius Sans One', sans-serif;
  font-size: 2.5rem;
  margin-bottom: -5px;
  margin-top: 1rem;
  text-align: center;
  color: #D77124;
  font-weight: 900;
}

.link-primary {
  color: #D77124 !important;
}

.serv-section1 {
  font-family: 'K2D', sans-serif;
  font-size: large;
}

.link-primary:hover {
  color: #b44e00 !important;
}

.bsb-service-6 .card-body p {
  font-size: 18px;
  color: #232323 !important;
  margin-bottom: 1rem;
  font-family: 'K2D', sans-serif;

  flex-grow: 1; /* Allow growing to fill space */
}

.bsb-service-6 .card-body a {
  color: #D77124;
  transition: color 0.5s ease;
  margin-top: auto; /* Push the link to the bottom */
}

.bsb-service-6 .card-body a:hover {
  color: #ED9A2B;
}

.bsb-service-6 hr {
  border-color: rgba(0, 0, 0, 0.1);
}

.bsb-service-6 .col-md-4:hover .card {
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: scale(1.05);
}

@media (max-width:992px) {
  .services-header {
    margin-top: 4rem ;
  }
}

/* Review Section Styles */
.review-star {
  cursor: pointer;
  margin-right: 1rem;
  margin-left: 1rem;
  font-size: 50px; /* Larger stars */
}

.review-star.text-muted {
  color: #E0E0E0; /* Lighter gray for unselected stars */
}

.review-stars {
  display: flex;
  justify-content: center; /* Centering stars */
  gap: 8px;
}

.review-star:hover, .review-star:hover ~ .review-star {
  color: #FFD700; /* Gold color for hover effect */
}

.feedback-form {
  text-align: center;
  width: 100%;
}

.review-modal {
  height: 100%;
}

@media  (max-width:480px) {
  .review-star {
    cursor: pointer;
    margin-right: .5rem;
    margin-left: .5rem;
    font-size: 40px; /* Larger stars */
  }
}

/* ServicesSection.css */
@media (max-width: 480px) {
 
    .modal-content {
      height: 100vh;
      border: none;
      border-radius: 0;
    }
  }

