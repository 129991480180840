/* Timecard container */
.timecard-container {
  padding: 40px;
  
}

/* Modal Header */
.employee-log-modal-header {
  background-color: #f8f9fa;
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
}

.employee-log-modal-title {
  font-weight: bold;
  font-family:Arial, Helvetica, sans-serif;
  color: #333;
}

.square-button {
  font-weight: 550;
}

.dashboard-internal-btn {
  color: white;
  background-color: #3498db !important;
}



.modal-body-text {
  font-size: medium !important;
  font-weight: 500;
}

.modal-content {
  max-width: 100%;
}

/* Modal Body */
.employee-log-modal-body {
  padding: 1rem;
  max-height: 600px;
  overflow-y: auto;
}

/* Form Section */
.employee-log-form {
  width: 100%;
}

.compact-row {
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.compact-label {
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
}

.compact-input,
.time-select,
.compact-textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  font-size: 0.875rem;
}

.compact-textarea {
  resize: none;
}

.compact-time-inputs {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}

.time-select {
  width: 33%;
  padding-right: 2rem; 
}

.compact-toggle {
  font-size: 1rem;
}

.compact-checkbox {
  font-size: 1rem;
}

/* Modal Footer */
.employee-log-modal-footer {
  background-color: #f8f9fa;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
}




/* Title and button row */
.timecard-title {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
}

/* Divider under heading */
.divider {
  border-top: 2px solid #ddd;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}

.divider-card {
  border-top: 2px solid #ddd;
  margin-top: 0.5rem;
  margin-bottom: .5rem;
}

/* Employee card styling */
.employee-card {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  transition: box-shadow 0.3s ease;
  cursor: pointer;
}

.employee-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.trash-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 20px;
  color: red;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.trash-icon:hover {
  transform: scale(1.1);
}

/* Add Employee Button */
.add-employee-btn {
  background-color: #3498db;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  color: white;
  font-weight: 550;
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-employee-btn:hover {
  background-color: #2674a8 !important;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3) !important; /* Slightly larger shadow on hover */

}



/* No employees available text */
.no-employees-text {
  font-size: 1.2rem;
  color: #555;
  text-align: center;
  margin-top: 20px;
}

@media (max-width: 768px) {
  /* Example: reduce padding on the container for mobile */
  .timecard-container {
    padding: 20px;
  }

  /* If any buttons or text are too large, adjust them here */
  .add-employee-btn {
    font-size: 16px;
    padding: 8px 16px;
  }
}
