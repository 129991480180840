/* Logout.css */

.logout-container {
  padding: 60px;
}

.left-column {
  text-align: left; /* Align content to the left */
}

.logout-title {
  margin-bottom: 20px;
  font-size: 2rem;
  color: #333;
}

.logout-profile-image-container {
  position: relative;
  width: 150px;
  height: 150px ;
  cursor: pointer;
}

.logout-profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 2px solid #c1c1c1;
  background-color: #f0f0f0;
  background-size: cover;
  background-position: center;
}

.logout-profile-image-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logout-profile-image-container:hover .overlay {
  background-color: rgba(0, 0, 0, 0.6);
}

.logout-profile-image-container .overlay-text {
  color: #fff;
  font-size: 1.2rem;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.logout-profile-image-container:hover .overlay-text {
  opacity: 1;
}

.vertical-divider {
  width: 1px;
  height: 100%;
  background-color: #ddd;
  margin: 0 auto;
}

.button-group .btn {
  min-width: 150px;
}

@media (max-width: 768px) {
  .vertical-divider {
    display: none;
  }
  .button-group {
    display: flex;
    flex-direction: column;
  }
  .button-group .btn {
    margin-bottom: 10px;
    width: 100%;
  }
}
