
.serv-marg {
  margin-top: 3rem;
}



.custom-accordion-wrapper .accordion-button {
  width: 100%;
}


.faq-section {
  margin-bottom: 5rem;
}

.serv-img {
  border-radius: 10px;
}

.faq-services {
    font-family: 'Julius Sans One', sans-serif;
    font-size: 2.3rem;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    text-align: center;
    color: #D77124;
    font-weight: 900;

  
}

.serv-page {
  font-weight: bold;
  margin-top: -1rem !important;
}

.service-item {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0) !important;
  text-align: left;
}

.service-icon {
  font-size: 3em;
  color: #d97706;
}

.service-item h3 {
  font-size: 2.3rem;
  margin-bottom: 10px;
  text-align: left !important;
}

.service-item p {
  color: #666;
  text-align: left !important;

}

.serv-head {
  text-align: left !important;
}

.serv-body {
  text-align: left;
  margin-left: -1rem !important;
}

.learn-more-button {
  padding: 10px 20px;
  background-color: #D77124; /* Updated to match the orange color */
  color: #D77124;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  margin-top: 15px;
}

.learn-more-button:hover {
  background-color: #bf6904; /* Slightly darker orange on hover */
}


/* Remove the blue background and set the orange color when the accordion is active */
.accordion-button:not(.collapsed) {
  background-color: #f8f9fa; /* Light grey background when active */
  color: #D77124; /* Orange text color when active */
  border-color: #D77124; /* Orange border when active */
}

/* Remove the blue border on focus */
.accordion-button:focus {
  box-shadow: none;
  outline: none;
}

/* Change text and background to orange on hover */
.accordion-button:hover {
  color: #D77124 !important; /* Orange text color on hover */
  background-color: #f8f9fa !important; /* Light grey background on hover */
  border-color: #D77124 !important; /* Orange border on hover */
}

.accordion-item .accordion-header .faq-head {
  font-family: 'K2D', sans-serif !important;
  font-weight: bold !important;
  font-size: large;
  color: #000; /* Default color when collapsed */
}

/* When the accordion is expanded */
.accordion-item .accordion-header:not(.collapsed) .faq-head {
  color: #D77124 !important; /* Orange text color when expanded */
}

/* Ensure the accordion button has the correct font family and weight when active or collapsed */
.accordion-button {
  font-family: 'K2D', sans-serif !important;
  font-weight: bold !important;
  font-size: large;
}



/* When the accordion is expanded */
.accordion-button:not(.collapsed) {
  color: #D77124 !important; /* Orange text color when expanded */
  border-color: #D77124 !important; /* Orange border when expanded */
}

/* Apply the K2D font to the Accordion Body */
.accordion-body {
  font-family: 'K2D', sans-serif !important;
  font-size: medium; /* You can adjust the font size as needed */
  color: #1d1d1d; /* Default text color for the accordion body */
}

/* Remove faint orange background on hover, just change the text color */
.accordion-button:hover {
  color: #D77124; /* Orange text color on hover */
  border-color: #000000; /* Keep border black on hover */
}


@media (max-width: 550px) {

  .about-serv-head {
    font-size: 2.3rem !important;
  }
}

/* Services.css */
@media (max-width: 992px) {
  .services-content .row .col-md-12:first-child {
    order: 2;
  }
  .services-content .row .col-md-12:last-child {
    order: 1;
  }
}

@media (max-width: 992px) {
  .img-card {
    margin-top: 2rem; /* Add margin above each image card on screens smaller than 992px */
  }
}
